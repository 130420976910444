import './style.min.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

// DOM elements
const nav = document.querySelector('nav')
const cover = document.querySelector('nav .cover')
const burger = document.querySelector('nav .burger')
const video = document.querySelector('#bg-video')
const vidContainer = document.querySelector('.vid-container')
const flexContainer = document.querySelector('.flex-container')
const followContainer = document.querySelector('.follow-container')
const pauseButton = document.querySelector('#pause-button')
const muteButton = document.querySelector('#mute-button')
const descriptionH3 = document.querySelectorAll('.description-container h3')
const section2 = document.querySelectorAll('.section-2')
const section2H2 = document.querySelectorAll('.section-2 h2')
const section2Icons = document.querySelectorAll('.section-2 .icon')
const section2Values = document.querySelectorAll('.section-2 .values h3')
const section2Texts = document.querySelectorAll('.section-2 .texts p')
const section3 = document.querySelectorAll('.section-3')

// Variables
let mouseMoveEnabled = false
let vidOpacity = 0.3
let videoFadedOut = false
let fromStart = true

// Prevent saving the scroll position
if (history.scrollRestoration) {
    history.scrollRestoration = 'manual'
}

// Initialize ScrollTrigger
gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({
    invalidateOnRefresh: true,
})

// Nav animation
burger.addEventListener('click', () => {
    nav.classList.toggle('open')
    burger.blur()
})
cover.addEventListener('click', () => {
    nav.classList.remove('open')
})

// Fade in title on load
window.addEventListener('load', () => {
    gsap.to('.title, .follow-container', {
        opacity: 1,
        delay: 0.5,
        duration: 1,
        ease: 'linear',
        onComplete: () => {
            video.playbackRate = 0.9
            video.play()
            // Mute video
            muteButton.addEventListener('click', () => {
                if (video.muted) {
                    muteButton.title = 'Mute'
                } else {
                    muteButton.title = 'Unmute'
                }
                video.muted = !video.muted
                muteButton.classList.toggle('unmuted')
            })
            // Play video
            pauseButton.addEventListener('click', () => {
                if (video.paused) {
                    video.play()
                    pauseButton.title = 'Pause'
                } else {
                    video.pause()
                    pauseButton.title = 'Play'
                }
                pauseButton.classList.toggle('paused')
            })
            document.addEventListener('keydown', (e) => {
                if (e.key !== 'Enter') return

                document.activeElement.click()
            })
        },
    })

    // Mousemove animation
    document.addEventListener('mousemove', onClientMove)
    function onClientMove(e) {
        // Don't run function if not necessary
        if (!mouseMoveEnabled) return

        // Move circle to follow mouse
        gsap.to('.video-decoration .mouse-follower', {
            x: e.clientX,
            y: e.clientY,
            xPercent: -50,
            yPercent: -50,
            overwrite: true,
            duration: 1.5,
            ease: 'expo.out',
        })
    }
})

// Start video on user interaction if suspended on load
video.addEventListener('suspend', () => {
    window.addEventListener('click', startVideo, { once: true })
    window.addEventListener('touchstart', startVideo, { once: true })
})
function startVideo() {
    // Don't start video if user paused
    if (pauseButton.classList.contains('paused')) return

    video.play()
}

// Fade in video
video.addEventListener('playing', () => {
    if (!fromStart) return

    videoFadedOut = false
    gsap.fromTo(
        video,
        {
            opacity: 0,
            volume: 0,
        },
        {
            opacity: Math.min(vidOpacity * 2, 1),
            volume: 0.2,
            duration: 2,
            ease: 'linear',
        }
    )
    gsap.set(video, {
        opacity: vidOpacity,
        delay: 3.3,
        onComplete: () => {
            fromStart = false
        },
    })
})
// Fade out video
video.addEventListener('timeupdate', () => {
    if (video.currentTime > 141 && !videoFadedOut) {
        gsap.to(video, {
            opacity: 0,
            volume: 0,
            duration: 4,
            ease: 'linear',
            onComplete: () => {
                fromStart = true
            },
        })
        videoFadedOut = true
    }
})

// Move title up
gsap.set(flexContainer, {
    x: 0,
    xPercent: -50,
    y: 0,
    yPercent: 0,
})
gsap.to(flexContainer, {
    scrollTrigger: {
        trigger: vidContainer,
        start: '0% top',
        end: '30% top',
        scrub: 0.6,
    },
    x: 0,
    xPercent: -50,
    y: '-6vh',
    yPercent: 0,
    ease: 'power1.inOut',
})
// Reveal second text
gsap.to(followContainer, {
    scrollTrigger: {
        trigger: vidContainer,
        start: '30% top',
        end: '100% top',
        scrub: 0.5,
    },
    x: 0,
    xPercent: 0,
    y: '-2.4em',
    yPercent: 0,
    ease: 'power2.inOut',
})

// Add animmation to every h3
gsap.to(descriptionH3, {
    scrollTrigger: {
        trigger: vidContainer,
        start: '100% top',
        end: `${100 + descriptionH3.length * 100}% top`,
        scrub: 0.5,
        immediateRender: true,
    },
    keyframes: {
        '0%': { autoAlpha: 0, y: '0em' },
        '50%': { autoAlpha: 1, y: '-3.5em', ease: 'circ.out' },
        '70%': { autoAlpha: 1, y: '-3.7em', ease: 'power1.out' },
        '100%': {
            y: (i) => {
                return i < descriptionH3.length - 1 ? '-7.4em' : '-3.7em'
            },
            ease: 'circ.in',
        },
    },
    ease: 'linear',
    duration: 1,
    stagger: 1,
})

// Video scroll animation: increase opacity, make video smaller, make text expand and fade out
let vidTl = gsap.timeline({
    scrollTrigger: {
        trigger: vidContainer,
        start: `710% top`,
        end: `790% top`,
        // markers: true,
        scrub: 0.5,
        onEnter: () => {
            vidOpacity = 1
        },
        onLeaveBack: () => {
            vidOpacity = 0.3
        },
    },
})
vidTl.fromTo(
    video,
    {
        opacity: vidOpacity,
        height: '100vh',
        width: 'Clamp(18em, 100vw, 100vw)',
        borderRadius: '0em',
        outlineWidth: '0em',
    },
    {
        opacity: 1,
        height: '50vh',
        width: 'Clamp(18em, 60vw, 90vw)',
        borderRadius: '1em',
        outlineWidth: '0.15em',
        ease: 'power2.inOut',
    }
)
vidTl.fromTo(
    flexContainer,
    {
        scale: 1,
        autoAlpha: 1,
    },
    {
        scale: 1.4,
        autoAlpha: 0,
        ease: 'expo.in',
    },
    '<'
)

// Animate video decoration
gsap.fromTo(
    '.video-decoration .text',
    {
        x: (i) => {
            return `${i == 0 ? 50 : -50}vmin`
        },
        xPercent: -50,
    },
    {
        scrollTrigger: {
            trigger: vidContainer,
            start: `710% top`,
            endTrigger: section2,
            end: `top top`,
            // markers: true,
            scrub: 0.5,
            onEnter: () => {
                mouseMoveEnabled = true
            },
            onEnterBack: () => {
                mouseMoveEnabled = true
            },
            onLeave: () => {
                mouseMoveEnabled = false
            },
            onLeaveBack: () => {
                mouseMoveEnabled = false
            },
        },
        x: (i) => {
            return `${i == 0 ? -50 : 50}vmin`
        },
        xPercent: -50,
        ease: 'linear',
    }
)

// Section 2 animation starts when section-2 covers the video completely, pauses the video
let section2Tl = gsap.timeline({
    scrollTrigger: {
        trigger: section2,
        start: `top 25%`,
        end: `bottom 50%`,
        // markers: true,
        onEnter: () => {
            video.pause()
            pauseButton.style.visibility = 'hidden'
            muteButton.style.visibility = 'hidden'
        },
        onLeaveBack: () => {
            pauseButton.style.visibility = 'visible'
            muteButton.style.visibility = 'visible'
            // Do not play video if user paused
            if (pauseButton.classList.contains('paused')) return
            video.play()
        },
        pin: section2H2,
        anticipatePin: 1,
        scrub: 0.5,
    },
})
// Make title pop in
section2Tl.to(
    section2H2,
    {
        keyframes: {
            '0%': { autoAlpha: 0, y: '0vh', yPercent: 0, scale: 1.5 },
            '8%': { autoAlpha: 1, y: '-25vh', yPercent: 0, scale: 1, ease: 'power2.out' },
            '96%': { autoAlpha: 1, y: '-25vh', yPercent: 0, scale: 1, ease: 'linear' },
            '100%': { autoAlpha: 0, y: '-25vh', yPercent: 0, scale: 1, ease: 'linear' },
        },
        ease: 'linear',
        duration: 13.8,
    },
    '<'
)
// Scroll stars
section2Tl.to(
    '.section-2 .star.small',
    {
        keyframes: {
            '0%': { autoAlpha: 0, y: '35vh', yPercent: 0 },
            '8%': { autoAlpha: 0.1 },
            '96%': { autoAlpha: 0.1 },
            '100%': { autoAlpha: 0, y: '35vh', yPercent: -80 },
            easeEach: 'linear',
        },
        ease: 'linear',
        duration: 13.8,
    },
    '<'
)
section2Tl.to(
    '.section-2 .star.medium',
    {
        keyframes: {
            '0%': { autoAlpha: 0, y: '65vh', yPercent: 0 },
            '8%': { autoAlpha: 0.1 },
            '96%': { autoAlpha: 0.1 },
            '100%': { autoAlpha: 0, y: '55vh', yPercent: -100 },
            easeEach: 'linear',
        },
        ease: 'linear',
        duration: 13.8,
    },
    '<'
)
section2Tl.to(
    '.section-2 .star.big',
    {
        keyframes: {
            '0%': { autoAlpha: 0, y: '90vh', yPercent: 0 },
            '8%': { autoAlpha: 0.1 },
            '96%': { autoAlpha: 0.1 },
            '100%': { autoAlpha: 0, y: '67vh', yPercent: -100 },
            easeEach: 'linear',
        },
        ease: 'linear',
        duration: 13.8,
    },
    '<'
)
// Fade in and scroll icons, titles, and texts
section2Tl.to(
    section2Icons,
    {
        keyframes: {
            '0%': { backgroundPositionX: 0, autoAlpha: 0 },
            '20%': { autoAlpha: 1 },
            '95%': { autoAlpha: 1 },
            '100%': { backgroundPositionX: '-10.8em', autoAlpha: 0 },
            easeEach: 'linear',
        },
        ease: 'linear',
        duration: 5,
        stagger: 4,
    },
    '<1'
)
section2Tl.to(
    section2Values,
    {
        keyframes: {
            '0%': { autoAlpha: 0 },
            '20%': { autoAlpha: 1 },
            '85%': { autoAlpha: 1 },
            '100%': { autoAlpha: 0 },
            easeEach: 'linear',
        },
        ease: 'linear',
        duration: 4,
        stagger: 4,
    },
    '<'
)
section2Tl.to(
    section2Texts,
    {
        keyframes: {
            '0%': { autoAlpha: 0, y: '5em', yPercent: 0 },
            '40%': { autoAlpha: 1, y: '0.1em', yPercent: 0, ease: 'circ.out' },
            '70%': { autoAlpha: 1, y: '-0.1em', yPercent: 0, ease: 'power1.out' },
            '100%': { autoAlpha: 0, y: '-4em', yPercent: 0, ease: 'circ.in' },
        },
        ease: 'linear',
        duration: 4,
        stagger: 4,
    },
    '<'
)

// Zoom out bg image, make title and bullet points appear
gsap.to('.section-3 .background-image', {
    scrollTrigger: {
        trigger: section3,
        start: `top top`,
        end: `bottom top`,
        scrub: true,
    },
    scale: 1,
    ease: 'linear',
})
gsap.to('.section-3 h2', {
    scrollTrigger: {
        trigger: section2,
        start: `bottom 40%`,
        end: `bottom -20%`,
        scrub: true,
        pin: '.section-3 h2',
        anticipatePin: true,
    },
    autoAlpha: 1,
    ease: 'linear',
})

// Signature
console.log(
    '%c \u2727 David\u00A0Lu \u2727 ',
    `
    text-align: center;
    font-family: Brush Script MT, Brush Script, Brush Script MT Italic, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: #ffffff;
    background: #003C6A;
    text-shadow: 2px 2px 2px #00000080;
    `
)
